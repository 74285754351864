import * as React from "react";
import { Helmet } from "react-helmet";

const DefaultLayout: React.FC = ({ children }) => {
  return (
    <>
      <Helmet>
        <title>til.</title>
      </Helmet>
      <main className="prose mx-auto px-10 py-20 min-h-screen">
        <div className="not-prose">
          <h1 className="font-bold font-serif text-2xl tracking-wide">til.</h1>
          <div className="h-8" />
          {children}
        </div>
      </main>
    </>
  );
};

export default DefaultLayout;
