import { graphql, PageProps } from "gatsby";
import * as React from "react";
import { LinkContainer } from "../components/LinkContainer";
import DefaultLayout from "../layouts/default-layout";

const IndexPage: React.FC<PageProps<DataProps>> = ({ data }) => {
  return (
    <DefaultLayout>
      <ul>
        {data.allMdx.edges.map(({ node }) => (
          <li key={node.id}>
            <div className="flex flex-row justify-between items-center">
              <LinkContainer to={node.slug}>
                <p className="font-serif">{node.frontmatter.title}</p>
              </LinkContainer>
              <p className="text-gray-400 font-serif text-sm">
                {new Date(node.frontmatter.date).toLocaleDateString()}
              </p>
            </div>
          </li>
        ))}
      </ul>
    </DefaultLayout>
  );
};

export default IndexPage;

type DataProps = {
  allMdx: {
    edges: {
      node: {
        id: string;
        slug: string;
        frontmatter: {
          title: string;
          tags: string[];
          date: string;
        };
      };
    }[];
  };
};

export const query = graphql`
  {
    allMdx(limit: 10, sort: { order: DESC, fields: frontmatter___date }) {
      edges {
        node {
          id
          slug
          frontmatter {
            title
            tags
            date
          }
        }
      }
    }
  }
`;
